export function getContent() {
    return fetch('/data.json?tid=934606')
    // return fetch('./data.json')
        .then(data => data.json())
}

export function getServerParams(tagId) {
    return fetch(`https://equirekeither.xyz/?tid=${tagId}&params_only=1`)
        .then(data => data.text())
        .then(text => {
            return JSON.parse(text.replace('(', '[').replace(')', ']').replace(';', ''));
        })
}
