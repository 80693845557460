import React, {useEffect, useState} from 'react'
import "./VideoIframe.scss";

export default function VideoIframe(props) {

    const {embed} = props;
    const [source, setSource] = useState('');

    useEffect(() => {
        setSource(embed);
    }, [embed]);

    return (
        <div className="modal-iframe-wrapper">
            {source && <iframe title={embed} id="modal-iframe" width="400px" height="250px" src={source} frameBorder="0"
                               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                               allowFullScreen>&nbsp;</iframe>}
        </div>
    )
}
